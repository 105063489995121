import Box from "@vertikal/e-prospera.ui.box";
import ParseHtml from "@vertikal/e-prospera.ui.parse-html";
import Text from "@vertikal/e-prospera.ui.text";
import Breadcrumbs, { Breadcrumb } from "@vertikal/e-prospera.ui.breadcrumbs"
import { graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { API } from "../api/api";
import {
  PolicyLayout,
  PolicyWrapper,
  PolicyContent,
  BreadCrumbsWrapper,
  BreadCrumbLink,
  BreadCrumbCurrent,
} from "../components/PrivacyPolicy/privacy-policy.styles";
import GlobalSettings from "../components/GlobalSettings";
import { useI18next } from "gatsby-plugin-react-i18next";
import MainMenu from "../components/MainMenu/MainMenu";

const PrivacyPolicyPage = () => {
  const [policy, setPolicy] = useState(null);
  const { language } = useI18next();

  useEffect(() => {
    async function getTermsofService() {
      try {
        const req = await fetch(`${API}/terms-backend/public/term-definitions?code=PRIVACY_POLICY`);
        const res = await req.json();
        if (!req.ok) {
          throw new Error(req.status);
        }
        setPolicy(res[0]);

      } catch (error) {
        console.log("error", error);
      }
    }
    if (!policy) {
      getTermsofService();
    }
    return;
  });

  return (
    <>
      <GlobalSettings />
      <MainMenu>
      <BreadCrumbsWrapper>
        <Breadcrumbs>
          <Breadcrumb id="breadcrumb-home">
            <BreadCrumbLink href="/" color="gray" variant="p">
              <Trans>common.home</Trans>
            </BreadCrumbLink>
          </Breadcrumb>
          <BreadCrumbCurrent>
            <Trans>terms.privacy_policy</Trans>
          </BreadCrumbCurrent>
        </Breadcrumbs>
      </BreadCrumbsWrapper>
      <PolicyLayout>
        {!!policy &&
          <PolicyWrapper id="privacy-policy_content">
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              style={{ gap: "1rem" }}
            >
              <Text variant="h3">{policy.name[language]}</Text>
              <Text variant="p">{policy.versionNo}</Text>
              <PolicyContent >
                <ParseHtml>
                  {policy.content[language]}
                </ParseHtml>
              </PolicyContent>
            </Box>
          </PolicyWrapper>
        }
      </PolicyLayout>
      </MainMenu>
    </>
  );
};

export default PrivacyPolicyPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
