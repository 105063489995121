import breakpoints from "@vertikal/e-prospera.styles.breakpoints";
import colors from "@vertikal/e-prospera.styles.colors";
import Box from "@vertikal/e-prospera.ui.box";
import { Breadcrumb } from "@vertikal/e-prospera.ui.breadcrumbs";
import Link from "@vertikal/e-prospera.ui.link";
import styled from "styled-components";

export const PolicyLayout = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PolicyWrapper = styled.div`
  padding: 5.5rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 768px;
`;

export const PolicyContent = styled(Box)`
  p {
    color: ${colors.gray.c900};
  }
  h4:first-of-type {
    margin: 0 0 1rem;
  }
`;

export const BreadCrumbsWrapper = styled(Box)`
  padding: 1.5rem 0 0 2rem;
  @media ${breakpoints.lg} {
    padding: 1.5rem 0 0 6rem;
  }
  div > p,
  div > div {
    margin-bottom: 0;
  }
`;

export const BreadCrumbLink = styled(Link)`
  span {
    font-weigth: 600;
    text-decoration: none;
  }
`;

export const BreadCrumbCurrent = styled(Breadcrumb)`
  color: ${colors.gray.c600};
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  margin-bottom: 0.25rem;
`;

export const Info = styled.div`
  display: flex;
  gap: 3.5rem;
  justify-content: center;
  flex-direction: column;
  @media ${breakpoints.md} {
    flex-direction: row;
  }
`;
